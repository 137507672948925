/* src/components/NotFoundPage.css */

.nfBody {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    background-color: #f0fdf4;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
    background: linear-gradient(135deg, #d1fae5 50%, #10b981 50%);
    color: #333;
  }
  
  .notFound-container {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    padding: 40px;
    max-width: 900px;
    text-align: center;
    width: 100%;
  }
  
  h1 {
    font-size: 3rem;
    color: #10b981;
    margin-bottom: 20px;
  }
  
  p {
    font-size: 1.2rem;
    color: #6b7280;
    margin-bottom: 30px;
  }
  
  .error-image {
    width: 100px;
    margin-bottom: 20px;
  }
  
  .suggestions {
    margin-top: 40px;
  }
  
  .suggestions h2 {
    font-size: 1.8rem;
    color: #065f46;
    margin-bottom: 20px;
  }
  
  .nfcards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .nfcard {
    background-color: #f0fdf4;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    max-width: 250px;
    text-align: left;
    transition: transform 0.3s ease;
  }
  
  .nfcard:hover {
    transform: translateY(-10px);
  }
  
  .nfcard a {
    text-decoration: none;
    font-size: 1rem;
    color: #10b981;
    font-weight: bold;
  }
  
  .home-link {
    display: inline-block;
    margin-top: 20px;
    padding: 15px 30px;
    background-color: #10b981;
    color: #fff;
    text-decoration: none;
    border-radius: 8px;
    font-size: 1.2rem;
    transition: background-color 0.3s ease;
  }
  
  .home-link:hover {
    background-color: #065f46;
  }
  
  .features {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
  }
  
  .feature-item {
    background-color: #d1fae5;
    padding: 20px;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    width: 180px;
    transition: transform 0.3s ease;
  }
  
  .feature-item:hover {
    transform: translateY(-10px);
  }
  
  .feature-item img {
    width: 60px;
    margin-bottom: 15px;
  }
  
  .feature-item h3 {
    font-size: 1.2rem;
    color: #065f46;
    margin-bottom: 10px;
  }
  
  .feature-item p {
    font-size: 0.95rem;
    color: #333;
  }
  
  .free-banner {
    margin-top: 30px;
    padding: 20px;
    background-color: #10b981;
    color: #fff;
    font-size: 1.5rem;
    border-radius: 8px;
  }
  