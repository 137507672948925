body {
    font-family: 'Arial', sans-serif;
    background-color: #f0f2f5; /* Fundo claro */
    color: #333; /* Texto principal */
}

.navbar {
    background-color: #000; /* Fundo preto */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.navbar-brand img {
    height: 50px;
}

.nav-link,
.navbar-toggler-icon,
.dropdown-item {
    color: #fff;
    margin-right: 10px;
    transition: color 0.3s;
}

.nav-link:hover,
.dropdown-item:hover {
    color: #4caf50; /* Verde Mock4Tech */
}

.login-btn {
    background-color: #4caf50; /* Botão de Login com verde Mock4Tech */
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.login-btn:hover {
    background-color: #45a049;
}

.filter-section {
    background-color: #e8f5e9; /* Fundo verde claro */
    padding: 20px 0;
}

.filter-section .provider-logo {
    width: 100px;
    height: auto;
    margin: 0 20px;
    cursor: pointer;
    transition: transform 0.3s;
}

.filter-section .provider-logo:hover {
    transform: scale(1.1);
}

.certification-card {
    background-color: #fff;
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    overflow: hidden;
}

.certification-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
}

.certification-card img {
    width: 100%;
    height: 120px;
    object-fit: contain;
    padding: 10px;
    background-color: #f8f9fa;
}

.card-title {
    color: #4caf50; /* Verde Mock4Tech */
    font-size: 1.25rem;
    font-weight: bold;
    margin-top: 10px;
}

.card-text {
    color: #666;
}

.btn-cert {
    background-color: #4caf50;
    color: #fff;
    border: none;
    transition: background-color 0.3s;
}

.btn-cert:hover {
    background-color: #45a049;
}

.no-certifications {
    text-align: center;
    padding: 40px 0;
    color: #666;
    font-size: 1.2rem;
}