/* QuizDashboard.css */

.quiz-dashboard-container {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
  }
  
  .quiz-card {
    border-radius: 12px;
    padding: 20px;
    background-color: #fff;
  }
  
  .quiz-title {
    font-weight: bold;
    color: #333;
  }
  
  .quiz-details {
    font-size: 1rem;
    color: #777;
  }
  
  .row {
    margin: 0;
    padding: 0;
  }
  
  .doughnut-container {
    max-width: 500px; /* Increase the size */
    height: 500px;    /* Increase the height */
    margin: auto;
  }
  
  .quiz-attempt {
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 20px;
  }
  
  .status-text.passed {
    color: green;
  }
  
  .status-text.failed {
    color: red;
  }
  
  .quiz-percentage {
    font-size: 2.5rem;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .time-taken,
  .completion-date {
    font-size: 1rem;
    color: #555;
  }
  
  .review-btn {
    font-size: 1.2rem;
    padding: 10px 20px;
    margin-top: 20px;
  }
  
  @media (max-width: 768px) {
    .quiz-attempt {
      font-size: 1.2rem;
    }
    .quiz-percentage {
      font-size: 2rem;
    }
    .review-btn {
      font-size: 1rem;
      width: 100%;
    }
    .doughnut-container {
      height: 300px;
      max-width: 300px; /* Adjust the size for smaller devices */
    }
  }
  