.article-container {
  padding: 1rem;
  max-width: 800px;
  margin: 3rem auto;
  margin-top: 0rem;
  margin-bottom: 0rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.relatedArticleTitle{
  min-height: 180px;
}

.nav-container {
  padding: 1rem;
  max-width: 800px;
  margin: 0 auto;
}

.btn-home{
  width:200px;
  text-align:cener;
  font-size: 20px;
}

.article-header {
  margin-bottom: 1.5rem;
}

.article-title {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.thumbnail-image {
  width: 100%;
  max-width: 600px; /* Optional: limits the max size */
  height: auto;
  border-radius: 8px;
  object-fit: cover;
}

.summary-box {
  margin-top: 1rem;
  padding: 0.75rem;
  background-color: #f9f9f9;
  border-radius: 5px;
  font-size: 1rem;
}

.article-text {
  font-size: 1rem;
  line-height: 1.6;
  margin-top: 1rem;
}

.article-text img{
  width:100% !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .article-title {
    font-size: 1.5rem;
  }
  
  .summary-box, .article-text {
    font-size: 0.95rem;
  }
}

.related-articles {
  background-color: #f4f4f9;
  padding: 20px;
  margin-top: 40px;
  border-radius: 8px;
}

.related-articles h3 {
  color: #4caf50;
}

.related-articles .card {
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/*
.thumbnail-image{
  width:450px;
  height:450px;
  text-align: center !important;
}

.article-header {
  margin-bottom: 20px;
}

.article-header h1 {
  font-size: 2.5rem;
  color: #4caf50;
  margin-bottom: 10px;
}

.article-meta {
  color: #888;
  margin-bottom: 20px;
}

.reader-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e8f5e9;
  padding: 10px 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.share-buttons {
  margin-bottom: 30px;
  text-align: center;
}

.share-buttons a {
  color: #4caf50;
  margin: 0 10px;
  font-size: 1.5rem;
}

.article-content {
  margin-bottom: 30px;
}

.article-content p {
  margin-bottom: 1.5rem;
}

.article-content img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin: 20px 0;
}

.highlight-box {
  background-color: #e8f5e9;
  border-left: 5px solid #4caf50;
  padding: 15px;
  margin: 30px 0;
  font-weight: bold;
  font-size: 1.1rem;
  color: #333;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.summary-box {
  background-color: #f0f0f0;
  padding: 15px;
  margin-bottom: 30px;
  border-left: 5px solid #4caf50;
  border-radius: 8px;
  font-size: 1rem;
  color: #555;
}


.comment-section {
  margin-top: 40px;
}

.comment-section h3 {
  color: #4caf50;
  margin-bottom: 20px;
}

.comment-form textarea {
  resize: none;
}

.ad-space {
  background-color: #e9ecef;
  padding: 20px;
  text-align: center;
  margin: 30px 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ad-space img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
} */
