body {
  font-family: 'Arial', sans-serif;
  background-color: #f0f2f5;
  color: #333;
}

.navbar {
  background-color: #000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.navbar-brand img {
  height: 50px;
}

.nav-link,
.navbar-toggler-icon,
.dropdown-item {
  color: #fff;
  margin-right: 10px;
  transition: color 0.3s;
}

.nav-link:hover,
.dropdown-item:hover {
  color: #4caf50;
}

.login-btn {
  background-color: #4caf50;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.login-btn:hover {
  background-color: #45a049;
}

.certification-details {
  background-color: #fff;
  border-radius: 8px;
  padding: 40px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.certification-details h2 {
  
  font-weight: bold;
  margin-bottom: 20px;
}

.certification-logo {
  max-width: 200px;
  margin-bottom: 20px;
}

.certification-details h3 {
  color: #333;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 15px;
}

.certification-details p,
.certification-details ul {
  color: #666;
  margin-bottom: 20px;
}

.certification-details ul {
  list-style-type: none;
  padding-left: 0;
}

.certification-details ul li {
  padding: 5px 0;
  position: relative;
  padding-left: 25px;
}

.certification-details ul li::before {
  position: absolute;
  left: 0;
  top: 0;
}

.content {
  text-align: left !important;
}

.top-ranked-section {
  background-color: #e8f5e9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
}

.top-ranked-section h3 {
  color: #4caf50;
  margin-bottom: 15px;
}

.ranked-user {
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.sticky-cta {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: #4caf50;
  color: #fff;
  text-align: center;
  padding: 15px;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: background-color 0.3s, transform 0.3s;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none; /* Remove sublinhado */
  border-radius: 5px;
}

.sticky-cta:hover {
  background-color: #45a049;
  transform: scale(1.05); /* Aumenta o tamanho ao passar o mouse */
}

footer {
  background-color: #000;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  margin-top: 40px;
}

footer a {
  color: #fff;
  margin: 0 10px;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}


.start-quiz-btn {
  font-size: 1rem;
  padding: 0.75rem 1rem;
  margin-bottom: 20px;
}

.sticky-cta a {
  color: #ffffff;
  text-decoration: none;
}

.sticky-cta:hover {
  background-color: #218838;
  text-decoration: none;
}
