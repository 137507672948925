/* Navbar logo */
.navbar-brand img {
    transition: transform 0.3s ease-in-out;
}

/* Scale and rotate on hover */
.navbar-brand img:hover {
    transform: scale(1.1) rotate(5deg); /* Aumenta o logo em 10% e o gira levemente */
}

/* General navbar link styles */
.nav-link,
.navbar-toggler-icon {
    color: #fff !important;
    margin-right: 10px;
    transition: color 0.3s;
}

/* Dropdown item styles */
.dropdown-item {
    color: #111111 !important;
    padding: 8px 12px; /* Ensure consistent padding */
    margin: 0; /* Remove any unwanted margin */
  }

/* Hover effects for nav links and dropdown items */
.nav-link:hover,
.dropdown-item:hover {
    color: #4caf50; /* Verde Mock4Tech */
}

/* Custom button styles */
.btn-custom {
    background-color: #17db4e !important;
    border: none;
    border-radius: 20px !important;
    transition: background-color 0.3s, transform 0.3s;
}

.btn-custom:hover {
    background-color: #c0c0c0 !important;
    color: #111 !important;
    transform: scale(1.05);
}

/* Navbar styles */
.navbar {
    background-color: #000 !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Styles for non-active nav items */
.navElem {
    color: #ffffff !important;
    transition: color 0.3s ease;
}

/* Styles for active nav items */
.navElem.active {
    color: #17db4e !important; /* Active color */
}

/* Transparent dropdown toggle */
.noBgDropdown {
    background-color: transparent !important;
    border: none !important;
    padding: 0;
}

.noBgDropdown:hover {
    color: #17db4e !important;
}

/* Login button styles */
.loginButton {
    color: #111 !important;
    background-color: #17bd4e !important;
    border-color: #17bd4e !important;
    width: 100px !important;
    height: 40px !important;
    border-radius: 20px !important;
    font-size: 18px !important;
    font-weight: bold !important;
    transition: background-color 0.3s ease;
}

/* Login button hover state */
.loginButton:hover {
    background-color: #c0c0c0 !important;
    border-color: none !important;
}

/* Responsive login button styles */
@media (max-width: 768px) {
    .loginButton {
        width: 100% !important; /* Full width on mobile */
        margin-top: 10px; /* Add spacing for better layout */
    }
}
