body {
    font-family: 'Montserrat', sans-serif;
    background-color: #f4f4f9;
    margin: 0;
    padding: 0;
    color: #333;
  }
  
  /* Estilos para o Menu Responsivo */
  .navbar {
    background-color: #007bff;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  
  .navbar .logo {
    font-size: 22px;
    font-weight: bold;
  }
  
  .navbar .menu {
    display: flex;
    gap: 20px;
  }
  
  .navbar .menu a {
    color: white;
    text-decoration: none;
    font-size: 16px;
  }
  
  .navbar .menu a:hover {
    text-decoration: underline;
  }
  
  .navbar .hamburger {
    display: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .menu-mobile {
    display: none;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: #007bff;
    padding: 15px;
    border-radius: 8px;
  }
  
  .menu-mobile.open {
    display: flex;
  }
  
  .menu-mobile a {
    color: white;
    text-decoration: none;
    font-size: 16px;
  }
  
  .menu-mobile a:hover {
    text-decoration: underline;
  }
  
  /* Estilos do Perfil */
  .profile-container {
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .quick-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    gap: 20px;
  }
  
  .quick-actions .action-btn {
    flex: 1;
    padding: 10px;
    background-color: #e3f2fd;
    color: #007bff;
    border: 2px solid #007bff;
    border-radius: 8px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .quick-actions .action-btn:hover {
    background-color: #007bff;
    color: #fff;
  }
  
  .profile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .profile-header .user-info {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .profile-header .user-info img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 2px solid #007bff;
  }
  
  .profile-header .user-info .user-details {
    display: flex;
    flex-direction: column;
  }
  
  .profile-header .user-info .user-details h2 {
    margin: 0;
    color: #333;
  }
  
  .profile-header .user-info .user-details p {
    margin: 5px 0 0;
    color: #777;
  }
  
  .profile-header .total-score {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 18px;
    color: #333;
  }
  
  .profile-header .total-score i {
    color: #FFD700;
    font-size: 24px;
  }
  
  .profile-header .notifications {
    position: relative;
    cursor: pointer;
  }
  
  .profile-header .notifications i {
    font-size: 24px;
    color: #007bff;
  }
  
  .profile-header .notifications-badge {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: #DC3545;
    color: white;
    font-size: 12px;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .profile-header .edit-btn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .profile-header .edit-btn:hover {
    background-color: #0056b3;
  }
  
  .profile-section {
    margin-top: 30px;
  }
  
  .profile-section h3 {
    margin-bottom: 15px;
    color: #333;
  }
  
  .statistics,
  .activity-log,
  .friend-options {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .stat-card,
  .activity-item,
  .friend-option {
    flex: 1;
    min-width: 280px;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  }
  
  .stat-card h4,
  .activity-item h4,
  .friend-option h4 {
    margin: 0;
    color: #007bff;
  }
  
  .stat-card p,
  .activity-item p,
  .friend-option p {
    color: #555;
  }
  
  .stat-card .chart {
    margin-top: 15px;
    background-color: #e3f2fd;
    height: 200px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #007bff;
    font-weight: bold;
  }
  
  .invite-btn {
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
  }
  
  .invite-btn:hover {
    background-color: #218838;
  }
  
  @media (max-width: 768px) {
    .navbar .menu {
      display: none;
    }
  
    .navbar .hamburger {
      display: block;
    }
  
    .certification-summary {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  