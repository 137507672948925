
.descriptionText{
    min-height:35px;
    font-size:16px;
}

.featured-section {
    background-color: #fff;
    padding: 40px 0;
    margin-bottom: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.certificationThumb{
    width: 100px;
    height:100px;
}

.featured-section .carousel-item img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 8px;
}

.filter-bar {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media (max-width: 767px) {
    .filter-bar {
      text-align: center;
    }
  }

.active-item {
    background-color: #007bff; /* Cor de fundo azul (cor padrão do Bootstrap) */
    color: white; /* Texto branco */
}

.filter-btn {
    background-color: #4caf50;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s;
    margin-right: 10px;
}

.filter-btn:hover {
    background-color: #45a049;
}

.search-bar {
    flex: 1;
}

.content-card {
    border: none;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    background-color: #fafafa;
}

.content-card:hover {
    transform: translateY(-5px);
    background-color: #fafafa;
    box-shadow: 0 0 15px rgba(0, 123, 255, 0.3);
    border: 1px solid #007bff;
}

.content-card img {
    border-radius: 8px 8px 0 0;
}

.content-card .card-body {
    padding: 20px;
}

.content-card .card-title {
    color: #4caf50;
    font-weight: bold;
    min-height:50px;
}

.content-card .btn-custom {
    background-color: #4caf50;
    color: #fff;
    transition: background-color 0.3s;
}

.content-card .btn-custom:hover {
    background-color: #45a049;
}

.pagination {
    justify-content: center;
}

footer {
    background-color: #000;
    color: #fff;
    padding: 20px 0;
    text-align: center;
    margin-top: 40px;
}

footer a {
    color: #fff;
    margin: 0 10px;
    text-decoration: none;
}

footer a:hover {
    text-decoration: underline;
}
