body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f9;
    color: #333;
    margin: 0;
    padding: 0;
}

.navbar {
    background-color: #007bff;
}

.custom-modal .modal-content {
    opacity: 1 !important; /* Garantir que o modal esteja visível */
    background-color: #fff !important; /* Ajustar o fundo se necessário */
  }
  
  .custom-modal .modal-backdrop {
    opacity: 0.8; /* Ajustar a opacidade do fundo */
  }

.navbar-brand {
    font-weight: bold;
    font-size: 1.5rem;
    color: #fff;
}

.review-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
}

.time-card {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    gap: 10px; /* Space between the buttons */
}


.optionResponse{
    text-align: left !important;
}

.question {
    text-align: left !important;
    font-size: 20px !important;
}

.question-review h3 {
    font-size: 1.2rem;
    color: #007bff;
    margin-bottom: 15px;
}

.options-container {
    margin-top: 15px;
}

.option {
    display: flex;
    align-items: center;
    background-color: #f4f4f9;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.option.correct {
    background-color: #d4edda;
    /* Verde suave */
    color: #155724;
    /* Texto verde escuro */
    border-color: #c3e6cb;
}

.nav-tabs .nav-link {
    background-color: transparent !important;
    color: #111 !important;
    /* border-top: none !important;
    border-left: none !important;
    border-right: none !important; */
  }
  
.nav-tabs .nav-link.active {
    border-bottom: solid 4px white !important;
    color: #111 !important;
    font-weight: bold !important;
}


.option.incorrect {
    background-color: #f8d7da;
    /* Vermelho suave */
    color: #721c24;
    /* Texto vermelho escuro */
    border-color: #f5c6cb;
}

.option:hover {
    background-color: #e2e3e5;
    /* Hover para todas as opções */
}

.btn-back,
.btn-nav {
    background-color: #007bff;
    color: #fff;
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    transition: background-color 0.3s;
}

.btn-back:hover,
.btn-nav:hover {
    background-color: #0056b3;
}

.btn-nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.modal-content {
    border-radius: 15px;
    /* Bordas arredondadas */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    /* Sombra suave */
}

.modal-body {
    font-size: 1rem;
    padding: 20px;
}

.selected-answer {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-weight: bold;
    font-size:20px;
    text-align: left;
}

.highlight-correct {
    color: #28a745;
    /* Verde para destaque de "Correto" */
    font-weight: bold;
}

.highlight-incorrect {
    color: #dc3545;
    /* Vermelho para destaque de "Incorreto" */
    font-weight: bold;
}

.feedback-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.feedback-container button {
    border: none;
    cursor: pointer;
    background-color: transparent;
    /* Remover fundo */
    font-size: 1.5rem;
    /* Tamanho maior para o ícone */
    padding: 8px;
    border-radius: 50%;
    /* Botões redondos */
    border: 2px solid transparent;
    /* Inicialmente sem borda */
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    /* Alinhamento vertical */
    align-items: center;
    /* Centraliza o conteúdo */
    margin-right: 10px;
    /* Espaço entre botões */
}

.feedback-container .like-btn i,
.feedback-container .dislike-btn i {
    color: #f0ad4e;
    /* Amarelo suave */
}

.feedback-container button:hover {
    border-color: #f0ad4e;
    /* Amarelo suave na borda */
    background-color: rgba(240, 173, 78, 0.1);
    /* Fundo amarelo suave no hover */
}

.feedback-container button.active {
    border-color: #f0ad4e;
    /* Cor de borda ao ser selecionado */
}

.feedback-container .count {
    font-size: 0.8rem;
    /* Tamanho menor para a contagem */
    color: #666;
    /* Cor neutra para o contador */
}

.feedback-container .comment-btn {
    background: none;
    border: none;
    margin-left: 10px;
    cursor: pointer;
    color: #007bff;
    /* Cor para o botão de comentários */
    transition: color 0.3s;
}

.feedback-container .comment-btn i {
    font-size: 1.5rem;
}

.feedback-container .comment-btn:hover i {
    color: #0056b3;
    /* Azul suave para hover */
}

.comment-section {
    margin-top: 20px;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.comment-input {
    margin-top: 20px;
    padding: 15px;
    border-top: 1px solid #ddd;
}

.comment-input textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    resize: none;
    margin-bottom: 10px;
    transition: border-color 0.3s;
}

.comment-input textarea:focus {
    border-color: #007bff;
    outline: none;
}

.comment-input button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.comment-input button:hover {
    background-color: #0056b3;
}

.comment-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    padding: 15px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-left: 4px solid #007bff;
}

.comment-item .comment-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.comment-item .comment-details {
    display: flex;
    align-items: center;
}

.comment-item .flag-icon {
    margin-right: 8px;
}

.comment-item .comment-user {
    font-weight: bold;
    margin-right: 10px;
}

.comment-item .comment-date {
    font-size: 0.85rem;
    color: #666;
}

.comment-item .comment-text {
    margin-bottom: 10px;
    color: #555;
}

.comment-feedback {
    display: flex;
    align-items: center;
}

.comment-feedback button {
    margin-left: 5px;
    background-color: transparent;
    /* Remover fundo */
    font-size: 1.2rem;
    /* Tamanho ajustado para o ícone */
    padding: 6px;
    border-radius: 50%;
    /* Botões redondos */
    border: 2px solid transparent;
    /* Inicialmente sem borda */
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    /* Alinhamento vertical */
    align-items: center;
    /* Centraliza o conteúdo */
}

.comment-feedback .like-btn i,
.comment-feedback .dislike-btn i {
    color: #f0ad4e;
    /* Amarelo suave */
}

.comment-feedback button:hover {
    border-color: #f0ad4e;
    /* Amarelo suave na borda */
    background-color: rgba(240, 173, 78, 0.1);
    /* Fundo amarelo suave no hover */
}

.comment-feedback button.active {
    border-color: #f0ad4e;
    /* Cor de borda ao ser selecionado */
}

.comment-feedback .count {
    font-size: 0.7rem;
    /* Tamanho menor para a contagem */
    color: #666;
    /* Cor neutra para o contador */
}

