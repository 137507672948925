.splash-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000; /* Cor de fundo */
    z-index: 9999; /* Para garantir que fica acima de outros elementos */
    text-align: center;
  }
  
  .mock4tech-animation {
    max-width: 80%;
    max-height: 50%;
    object-fit: contain;
  }
  
  .loading-spinner {
    margin-top: 20px;
  }
  
  .loading-text {
    font-size: 1.2rem;
    color: #fff;
  }
  