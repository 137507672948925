
  
  .botao-contador {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #6a11cb, #2575fc); /* Gradiente */
    color: white;
    border-radius: 50px;
    padding: 15px 40px;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Sombra */
    text-decoration: none;
    transition: all 0.3s ease-in-out; /* Transição suave */
    cursor: pointer;
  }
  
  .botao-contador:hover {
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.4); /* Aumenta a sombra no hover */
    transform: translateY(-5px); /* Eleva levemente o botão */
    background: linear-gradient(135deg, #5b0eb5, #1f64e5); /* Gradiente mais escuro no hover */
  }
  
  .botao-contador span {
    font-size: 28px; /* Maior destaque para o número */
  }