
  .benefits-section {
    background-color: #e8f5e9; /* Fundo verde claro */
    padding: 60px 0;
}
  
  .btn-custom {
    background-color: #17db4e !important;
    border: none;
    border-radius: 20px !important;
    transition: background-color 0.3s, transform 0.3s;
  }

  .btnReadMore {
    background-color: #17db4e !important;
    border: none;
    border-radius: 20px !important;
    font-size: 16px;
  }

  .btn-custom:hover {
    background-color: #c0c0c0 !important;
    color:#111 !important;
    transform: scale(1.05);
  }

  .btn-custom2 {
    border: none;
    border-radius: 20px !important;
    transition: background-color 0.3s, transform 0.3s;
    margin-left:20px;
  }

  .btn-custom2:hover {
    background-color: #c0c0c0 !important;
    color:#111 !important;
    transform: scale(1.05);
  }

  /* Header */
  .header {
    background-color: #006400; /* Verde escuro */
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .header h1 {
    color: white;
    margin: 0;
  }
  
  nav a {
    color: #fff; /* Texto Branco */
    margin: 0 15px;
    text-decoration: none;
  }
  
  /* Main Section */
  .main-section {
    padding: 20px;
  }
  
  .provider-section {
    margin: 40px 0;
  }
  
  .provider-title {
    color: #fff; /* Texto Branco */
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .carousel {
    display: flex;
    overflow: hidden;
    position: relative;
    max-width: 100%;
    height:300px;
    margin: 0 auto;
  }
  
  .card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  /* On hover, scale the card and increase the shadow */
  .card:hover {
    transform: scale(1.05);
    background-color: #c0c0c0;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }


/* Container for the testimonial card */
.testimonial-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
}

/* Card with shadow and rounded corners */
.card {
  max-width: 400px;
  width: 100%;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 24px;
  text-align: center;
  transition: transform 0.3s;
}

.card:hover {
  transform: translateY(-5px);
}

/* User avatar and name styling */
.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  margin-top:30px;
}

.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #4caf50;
}

.author-name {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
}

/* Testimonial text styling */
.testimonial-text {
  font-size: 1rem;
  font-style: italic;
  color: #555;
  margin: 16px 0;
  height:80px;
}

/* Certification section styling */
.certification {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
  height: 85px;
}

.certification-img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.certification-title {
  font-size: 0.9rem;
  font-weight: 500;
  color: #333;
  text-align: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .btn-custom2 {
    margin-left:0px;
  }

  .card {
    padding: 16px;
  }

  .avatar {
    width: 60px;
    height: 60px;
  }

  .author-name {
    font-size: 1rem;
  }

  .testimonial-text {
    font-size: 0.95rem;
  }

  .certification-img {
    width: 40px;
    height: 40px;
  }

  .certification-title {
    font-size: 0.85rem;
  }
}


  

  .carousel-slide {
    display: flex;
    animation: slide 20s linear infinite; /* Animação infinita com velocidade média */
  }
  
  .carousel-item {
    width: 250px !important;
    height:250px !important;
    margin: 0 10px;
    background-color: #1a1a1a; /* Fundo Cinza Escuro */
    padding: 10px;
    border-radius: 10px;
    text-align: center;
  }
  
  .carousel-item a {
    text-decoration: none;
    color: #fff; /* Texto Branco */
  }
  
  .carousel-item img {
    /* max-width: 100%;
    height: auto; */
    height:250px;
    width:250px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  @keyframes slide {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  /* Ajustes de responsividade */
  @media (max-width: 768px) {
    .carousel-item {
      min-width: 80%;
    }
  }
  
  /* Footer */
  .footer {
    background-color: #000; /* Preto */
    color: white;
    text-align: center;
    padding: 20px;
    margin-top: 30px;
  }
  
  .footer a {
    color: #006400; /* Verde escuro */
    text-decoration: none;
    margin: 0 10px;
  }
  
  .badge-success{
    background-color: #111 !important;
    color: #fff !important
  }

  @media (max-width: 768px) {
    .heroSection {
      background-size: contain;
      background-repeat: no-repeat;
      height: 50vh; /* Use viewport height to make it responsive */
      padding: 50px 0; /* Reduce padding for mobile */
    }
  
    .heroTitle {
      font-size: 1.5rem; /* Smaller title */
    }
  
    .heroText {
      font-size: 1rem; /* Smaller text */
    }
  
    .btn-custom {
      font-size: 0.9rem; /* Adjust button size */
    }
  }