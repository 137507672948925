/* App.css */

  
  .containerData {
    max-width: 1000px;
    margin: 40px auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .headerText {
    color: #007bff;
    text-align: center !important;
    font-size: 34px;
  }
  
  .login-btn-right {
    float: right;
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    font-size: 14px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s, box-shadow 0.3s;
  }
  

  .loginBtn {
    font-size:20px !important;
  }

  .login-btn-right:hover {
    background-color: #218838;
  }
  
  .back-btn-container {
    align-items: center !important;
    text-align: center !important;
    gap: 10px; /* Optional: adds a small gap between the buttons */
  }
  
  .back-btn-container a {
    text-decoration: none; /* Optional: to remove underline if you want */
  }
  
  /* Responsive layout */
  @media (max-width: 768px) {
    .back-btn-container {
      flex-direction: column; /* Stack buttons vertically */
      align-items: flex-start; /* Align the buttons to the left */
    }
  
    .back-btn-container a {
      width: 100%; /* Make the buttons take full width on mobile */
    }
  }
  
  .back-btn {
    padding: 8px 15px;
    background-color: transparent;
    color: #007bff;
    font-size: 20px;
    border: 1px solid #007bff;
    border-radius: 5px;
    text-decoration: none;
    text-align: center !important;
    font-weight: normal;
    transition: background-color 0.3s, color 0.3s;

    display: flex;
    justify-content: center; /* Centraliza o ícone e o texto horizontalmente */
    align-items: center; /* Alinha verticalmente o ícone e o texto */
    text-align: center;

  }
  
  .back-btn i {
    margin-right: 8px;
  }
  
  .back-btn:hover {
    background-color: #007bff;
    color: white;
  }
  


  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .cardQuiz {
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 20px;
    text-align: center;
    background-color: #fafafa;
    transition: box-shadow 0.3s, background-color 0.3s, border 0.3s;
    cursor: pointer;
    flex: 1 1 45%;
    margin: 10px;
    box-sizing: border-box;
  }
  
  /* Estilo da seção do título e da tag */
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .card-header h5{
    margin: 0;
    font-size: 1.5rem;
    text-align: center !important;
  }
  
  .tag {
    padding: 5px 10px;
    background-color: #f1f1f1;
    border-radius: 5px;
    font-size: 0.9rem;
    font-weight: bold;
  }
  
  /* Responsividade: Para telas menores */
  @media (max-width: 768px) {
    .card-header {
      flex-direction: column; /* Empilhar o título e a tag */
      align-items: flex-start;
    }
  
    .tag {
      margin-top: 10px; /* Espaçamento entre o título e a tag no modo mobile */
    }
  }
  
  .cardQuiz:hover {
    background-color: #f0f8ff;
    box-shadow: 0 0 15px rgba(0, 123, 255, 0.3);
    border: 1px solid #007bff;
  }
  
  .tag {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 16px;
    color: white;
    width: 200px !important;
  }
  
  .nivel-INTRO {
    background-color: #28a745;
  }
  
  .nivel-EASY {
    background-color: #90ee90;
    color: #000;
  }
  
  .nivel-MEDIUM {
    background-color: #ffc107;
    color: #000;
  }
  
  .nivel-HARD {
    background-color: #ff9800;
  }
  
  .nivel-VERY_HARD {
    background-color: #f44336;
  }
  
  .nivel-EXPERT {
    background-color: #b71c1c;
  }
  
  .cardQuiz h3 {
    margin-top: 0;
    font-size: 1.5em;
  }
  
  .cardQuiz p {
    margin: 10px 0;
  }
  
  @media (max-width: 768px) {
    .cardQuiz {
      flex: 1 1 100%;
      margin: 10px 0;
    }
  
    .login-btn-right {
      display: block;
      text-align: center;
      margin-top: 20px;
      float: none;
    }
  }

.filter-container {
  margin-bottom: 1.5rem;
}

/* ExamQuizzes.css */
.active-item {
  background-color: #007bff; /* Cor de fundo azul (cor padrão do Bootstrap) */
  color: white; /* Texto branco */
}

.active-item:hover {
  background-color: #0056b3; /* Cor um pouco mais escura ao passar o mouse */
}

@media (max-width: 768px) {
  .filter-container {
    display: block;
  }
}

@media (min-width: 768px) {
  .filter-container {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
}
