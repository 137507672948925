body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f9;
    color: #333;
}

.navbar {
    background-color: #007bff;
}

.navbar-brand {
    font-weight: bold;
    font-size: 1.5rem;
    color: #fff;
}

.nav-link {
    color: #fff !important;
    font-size: 1rem;
}

.nav-link:hover {
    color: #f8f9fa !important;
}

.quiz-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.quiz-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.time-card {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.time-card .timer {
    background-color: #ff9800;
    color: #fff;
    padding: 8px 15px;
    border-radius: 5px;
    display: flex;
    text-align: center !important;
    align-items: center !important;
    font-size: 1rem;
}

.time-card .timer i {
    margin-right: 5px;
}

.finish-btn {
    padding: 8px 15px;
    background-color: #f44336;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
}

.finish-btn i {
    margin-right: 5px;
}

.finish-btn:hover {
    background-color: #e53935;
}

.progress-bar {
    height: 20px;
    background-color: #4caf50;
}

.question-container {
    margin-bottom: 20px;
    text-align: left;
    transition: none; /* Disable transitions */
}

.question-container h2 {
    font-size: 1.5rem;
    color: #333;
}

.options-container {
    margin-top: 10px;
}

.option {
    display: flex;
    align-items: center;
    background-color: #f4f4f9;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.option:hover {
    background-color: #e8f5e9;
}

.option input {
    margin-right: 10px;
}

.quiz-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.quiz-footer button {
    width: 48%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
}

.quiz-footer button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.prev-btn {
    background-color: #007bff;
}

.next-btn {
    background-color: #4caf50;
}

.prev-btn:hover:not(:disabled) {
    background-color: #0056b3;
}

.next-btn:hover:not(:disabled) {
    background-color: #45a049;
}

.ad-space {
    background-color: #e9ecef;
    padding: 20px;
    text-align: center;
    margin: 30px 0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ad-space img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

.feedback-container {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.feedback-container button {
    border: none;
    margin-right: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    color: #fff;
    font-size: 1.2rem;
    padding: 8px 12px;
    border-radius: 5px;
}

.feedback-container .like-btn {
    background-color: #28a745; /* Verde para like */
}

.feedback-container .dislike-btn {
    background-color: #dc3545; /* Vermelho para dislike */
}

.feedback-container button.active {
    opacity: 1;
}

.feedback-container button.inactive {
    background-color: #6c757d; /* Cinza para estado inativo */
}

.feedback-container .comment-btn {
    background: none;
    border: none;
    margin-left: 10px;
    cursor: pointer;
    color: #777; /* Cor suave para botões */
    transition: color 0.3s;
}

.feedback-container .comment-btn i {
    font-size: 1.5rem;
}

.feedback-container .comment-btn:hover i {
    color: #4caf50; /* Verde suave para hover */
}

.comment-section {
    display: none;
    margin-top: 20px;
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.comment-input {
    margin-top: 20px;
}

.comment-input textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    resize: none;
}

.comment-input button {
    margin-top: 10px;
    background-color: #4caf50;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.comment-input button:hover {
    background-color: #45a049;
}

.quiz-footer .btn {
    padding: 10px;
    font-size: 1.1rem;
  }
  
@media (max-width: 576px) {
    .quiz-footer button {
        width: 100%;
        margin-bottom: 10px;
    }

    .time-card {
        flex-direction: column;
        align-items: center;
    }

    .time-card .finish-btn {
        margin-top: 10px;
        width: 100%;
        justify-content: center;
    }
}


/* Estilos para botões no desktop */
.quiz-footer .prev-btn, .quiz-footer .next-btn {
    margin: 0 8px;
  }
  
  /* Estilos para dispositivos móveis */
  @media (max-width: 768px) {
    .quiz-footer .prev-btn, .quiz-footer .next-btn {
      margin: 0 5px; /* adiciona um espaçamento horizontal */
      width: calc(50% - 10px); /* Reduz a largura dos botões para caber melhor na tela */
    }
  }

  
  /* Remover para criação de um componente */
  .levelDescription {
    padding: 6px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    max-width: 30%;
    min-width: 15%;
    text-align: center;
  }
  .levelDescription-INTRO {
    background-color: #28a745;
  }
  
  .levelDescription-EASY {
    background-color: #90ee90;
  }
  
  .levelDescription-MEDIUM {
    background-color: #ffc107;
  }
  
  .levelDescription-HARD {
    background-color: #ff9800;
  }
  
  .levelDescription-VERY_HARD {
    background-color: #f44336;
  }
  
  .levelDescription-EXPERT {
    background-color: #b71c1c;
  }

  .codeQuestion {
    position: fixed;
    top: 10%;
    right: 1%;
    color: #c6c6c6;
  }

  @media (max-width: 405px) {
    .codeQuestion {
        top: 15%;
    }
    
    .questionHeader{
        justify-content: center !important;
        display:flex;
    }
    .questionCount{
        justify-content: center !important;
        display:flex;
        margin-bottom: 5px !important;
    }
  }
