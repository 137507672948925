/* General Styling for the Modal */

.modal-content {
  position: relative;
  background-color: #fff;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 90%;
  max-width: 500px;
  max-height: 80vh;
  overflow-y: auto; /* Add overflow for content that exceeds the modal */
  border-radius: 8px;
}

.close {
  position: absolute;
  top: 10px;
  right: 15px;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/* Adjust button styles to ensure one button per line in smaller devices */
.button-group .d-grid {
  gap: 10px;
}

@media (max-width: 768px) {
  .button-group .d-grid {
    display: block; /* Buttons stack vertically */
  }
}

/* Updated Quiz Card for better layout */
.quiz-card .button-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 15px;
}

.quiz-card button {
  transition: background-color 0.3s;
}

/* General Styling for the Modal */
.modal-body {
  max-height: calc(100vh - 210px); /* Ensures modal content fits without cutting off */
  overflow-y: auto; /* Allows content to scroll if needed */
}

.modal-body p {
  font-size:16px !important;

}

/* Ensure buttons are stacked for smaller screens */
@media (max-width: 768px) {
  .button-group .d-grid {
    display: block; /* Buttons will stack vertically */
  }
}
